import React from "react"
import Img from "gatsby-image"
import LinkOutArrow from "../images/icons/linkOutArrowBlack.svg"

export default props => {
  return (
    <div className="space-y-6 light-1-bg relative">
      <div>
        <Img fluid={props.articleImage.fluid} className="sm:h-52" />
      </div>
      <div className="pb-4">
        <div className="space-y-2 pl-8 pr-4">
          <span className="eyebrow">{props.category}</span>
          <h5 className="dark-1">{props.headline}</h5>
          <div>
            <span className="gray-2 body-3">{props.date}</span>
          </div>
        </div>
        <div className="absolute bottom-0 right-0">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.articleLink}
            className="text-black"
          >
            <img src={LinkOutArrow} alt="link" />
          </a>
        </div>
      </div>
    </div>
  )
}
