import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArchiveHero from "../components/archivehero"
import Archive from "../components/archive"

const NewsArchive = () => (
  <Layout>
    <SEO title="Archive" />
    <ArchiveHero />
    <div className="bg-white">
      <Archive />
    </div>
  </Layout>
)

export default NewsArchive
