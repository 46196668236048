import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ArchivePost from "./newsroompost"


const LatestPosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulNewsArticle {
        nodes {
          articleImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          articleLead
          articleLink
          category
          date
          headline
        }
      }
    }
  `)

  const newsData = data.allContentfulNewsArticle.nodes.reverse()

  return (
    <div className="bg-white">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between relative -top-24 space-y-8 lg:space-y-0">
        <div className="sm:grid sm:grid-cols-2 sm:gap-y-16 lg:grid-cols-3 space-y-8 sm:space-x-8 sm:space-y-0">
          {newsData.map(ArchivePost)}
        </div>
      </div>
    </div>
  )
}

export default LatestPosts
