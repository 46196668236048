import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const ArchiveHero = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulNewArchivePage {
        heroHeading
        heroBackgroundImageDesktop {
          fluid(maxWidth: 1920, quality: 85) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        heroBackgroundImageMobile {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  `)

  const sources = [
    data.contentfulNewArchivePage.heroBackgroundImageMobile.fluid,
    {
      ...data.contentfulNewArchivePage.heroBackgroundImageDesktop.fluid,
      media: `(min-width: 1024px)`,
    },
  ]

  return (
    <BackgroundImage fluid={sources}>
      <div className="container mx-auto t-grid flex pt-36 pb-56 lg:py-64 lg:items-center">
        <h1 className="col-span-3 lg:col-start-1 lg:col-span-7 color-white">
          {data.contentfulNewArchivePage.heroHeading}
        </h1>
      </div>
    </BackgroundImage>
  )
}

export default ArchiveHero